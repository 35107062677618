// @mui
import { alpha } from '@mui/material/styles';
export function bgBlur(props) {
    var color = (props === null || props === void 0 ? void 0 : props.color) || '#000000';
    var blur = (props === null || props === void 0 ? void 0 : props.blur) || 6;
    var opacity = (props === null || props === void 0 ? void 0 : props.opacity) || 0.8;
    var imgUrl = props === null || props === void 0 ? void 0 : props.imgUrl;
    if (imgUrl) {
        return {
            position: 'relative',
            backgroundImage: "url(".concat(imgUrl, ")"),
            '&:before': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9,
                content: '""',
                width: '100%',
                height: '100%',
                backdropFilter: "blur(".concat(blur, "px)"),
                WebkitBackdropFilter: "blur(".concat(blur, "px)"),
                backgroundColor: alpha(color, opacity),
            },
        };
    }
    return {
        backdropFilter: "blur(".concat(blur, "px)"),
        WebkitBackdropFilter: "blur(".concat(blur, "px)"),
        backgroundColor: alpha(color, opacity),
    };
}
export function bgGradient(props) {
    var direction = (props === null || props === void 0 ? void 0 : props.direction) || 'to bottom';
    var startColor = props === null || props === void 0 ? void 0 : props.startColor;
    var endColor = props === null || props === void 0 ? void 0 : props.endColor;
    var imgUrl = props === null || props === void 0 ? void 0 : props.imgUrl;
    var color = props === null || props === void 0 ? void 0 : props.color;
    if (imgUrl) {
        return {
            background: "linear-gradient(".concat(direction, ", ").concat(startColor || color, ", ").concat(endColor || color, "), url(").concat(imgUrl, ")"),
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        };
    }
    return {
        background: "linear-gradient(".concat(direction, ", ").concat(startColor, ", ").concat(endColor, ")"),
    };
}
// ----------------------------------------------------------------------
export function textGradient(value) {
    return {
        background: "-webkit-linear-gradient(".concat(value, ")"),
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    };
}
// ----------------------------------------------------------------------
export function filterStyles(value) {
    return {
        filter: value,
        WebkitFilter: value,
        MozFilter: value,
    };
}
// ----------------------------------------------------------------------
export var hideScrollbarY = {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
};
// ----------------------------------------------------------------------
export var hideScrollbarX = {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
};
